const photos = [
    { src: "/assets/img/work/certificate-1.jpg", 
        width: 1052, 
        height: 743,
        title: "Japan Edu Fair 2021",
        description: "Japan Education Fair 2021",
    },
    { src: "/assets/img/work/certificate-2.jpg", 
        width: 1048, 
        height: 742,
        title: "LKTIN PESC 2021 Committe",
        description: "LKTIN PESC 2021 Committe by Workshop Elektro Organization",
    },
    { src: "/assets/img/work/certificate-3.jpg", 
        width: 981, 
        height: 687,
        title: "Backend Fundamental",
        description: "Backend Fundamental: Introduction Rest Api with AdonisJs 2022 by Dicoding",
    },
    { src: "/assets/img/work/certificate-4.jpg", 
        width: 570, 
        height: 733,
        title: "Duolingo 100 Score",
        description: "Get 100 score in Duolingo (B2)",
    },
    { src: "/assets/img/work/certificate-5.jpg", 
        width: 1047, 
        height: 735,
        title: "Game Developer Forum",
        description: "Participant in The 9th Cyber Education Forum with Game Developer theme",
    },
    { src: "/assets/img/work/certificate-7.jpg", 
        width: 1047, 
        height: 735,
        title: "LTDC 2021",
        description: "Line Tracer Design Contest 2021 Committe by Workshop Elektro",
    },
    { src: "/assets/img/work/certificate-8.jpg", 
        width: 1045, 
        height: 740,
        title: "Fesmaro 2021",
        description: "Electrical Student Creativity Festival (Fesmaro) Participant",
    },
    { src: "/assets/img/work/certificate-9.jpg", 
        width: 1045, 
        height: 740,
        title: "Beginner Flutter (Dicoding) 2022",
        description: "Learn Beginner Flutter by Dicoding 2022",
    },
    { src: "/assets/img/work/certificate-10.jpg", 
        width: 1045, 
        height: 740,
        title: "National Startup Competition 2022",
        description: "Participant in National Startup Competition 2022",
    },
    { src: "/assets/img/work/certificate-11.jpg", 
        width: 1045, 
        height: 740,
        title: "TOEFL ITP Prediction 2022",
        description: "TOEFL ITP Prediction 2022",
    },
    { src: "/assets/img/work/certificate-12.jpg", 
        width: 1045, 
        height: 740,
        title: "JavaScript Programming Basics",
        description: "JavaScript Programming Basics by Dicoding 2022",
    },
    { src: "/assets/img/work/certificate-13.jpg", 
        width: 1045, 
        height: 740,
        title: "Creating Back-End Apps for Beginners",
        description: "Creating Back-End Apps for Beginners by Dicoding 2022",
    },
    { src: "/assets/img/work/certificate-14.jpg", 
        width: 1045, 
        height: 740,
        title: "AWS Cloud Practitioner Essential",
        description: "AWS Cloud Practitioner Essential by Dicoding 2022",
    },
    { src: "/assets/img/work/certificate-15.jpg", 
        width: 1045, 
        height: 740,
        title: "Niagahoster WordPress Course 2022",
        description: "Niagahoster WP Course by IKA UM 2022",
    },
    { src: "/assets/img/work/certificate-16.jpg", 
        width: 1045, 
        height: 740,
        title: "HCIA-Cloud Service V3 Course 2022",
        description: "Huawei Cloud Service Course by Huawei 2022",
    },
    { src: "/assets/img/work/certificate-17.jpg", 
        width: 1045, 
        height: 740,
        title: "Finalis Gemastik Competition 2022",
        description: "Finalis in Game Development by Gemastik Competition 2022",
    },
    { src: "/assets/img/work/certificate-18.jpg", 
        width: 1045, 
        height: 740,
        title: "Finalis Startup Competition (IWDM) 2022",
        description: "Finalis in Student Digital Entrepreneurial Innovation (IWDM) and get funding",
    },
    { src: "/assets/img/work/certificate-19.png", 
        width: 1045, 
        height: 740,
        title: "Road to Gemastik and LIDM Committe 2022",
        description: "Road to Gemastik and LIDM Comitte by Workshop Elektro",
    },
    { src: "/assets/img/work/certificate-20.jpg", 
        width: 1045, 
        height: 740,
        title: "LKTIN PESC 2022 Committee",
        description: "LKTIN PESC 2022 Committee by Workshop Elektro",
    },
    { src: "/assets/img/work/certificate-21.jpg", 
        width: 1045, 
        height: 740,
        title: "LTDC 2022 Committee",
        description: "Line Tracer Design Contest 2022 Committee by Workshop Elektro",
    },
    { src: "/assets/img/work/certificate-22.jpg", 
        width: 1045, 
        height: 740,
        title: "Cloud Computing Training",
        description: "Cloud Computing Training in Digital Talent Scholarship (2022)",
    },
    { src: "/assets/img/work/certificate-23.jpg", 
        width: 1045, 
        height: 740,
        title: "Learn the basics of web programming",
        description: "Learn the basics of web programming by Dicoding",
    },
    { src: "/assets/img/work/certificate-24.jpg", 
        width: 1045, 
        height: 740,
        title: "Create back-end applications with GCP",
        description: "Learn to create back-end applications for beginners with Google Cloud (Dicoding) 2023",
    },
    { src: "/assets/img/work/certificate-25.jpg", 
        width: 1045, 
        height: 740,
        title: "The Bits and Bytes of Computer Networking",
        description: "The Bits and Bytes of Computer Networking by Coursera",
    },
    { src: "/assets/img/work/certificate-26.jpg", 
        width: 1045, 
        height: 740,
        title: "System Administration and IT Infrastructure Service",
        description: "System Administration and IT Infrastructure Service by Coursera",
    },
    { src: "/assets/img/work/certificate-27.jpg", 
        width: 1045, 
        height: 740,
        title: "Become a Google Cloud Architect",
        description: "Become a Google Cloud Architect (Dicoding) 2023",
    },
    { src: "/assets/img/work/certificate-28.jpg", 
        width: 1045, 
        height: 740,
        title: "Become a Google Cloud Engineer",
        description: "Become a Google Cloud Engineer (Dicoding) 2023",
    },
    { src: "/assets/img/work/certificate-29.jpg", 
        width: 1045, 
        height: 740,
        title: "ACE Google Cloud",
        description: "Google Cloud Certified Associate Cloud Engineer (ACE)",
    },

  ];

  export default photos;